import store from './store/store';
import {createRouter, createWebHistory} from "vue-router";

import OrdersList from './views/OrdersList';
import Job from './views/Jobs/Job.vue';

const QuotingPortalSetupIndex = () => import('./views/AccountInformation/QuotingPortalSetup/QuotingPortalSetupIndex.vue')
import _ from "lodash";
// import {defineAsyncComponent} from "vue";


export const ifNotAuthenticated = (to, from, next) => {
  if (!request.hasToken()) {
    next();
    return;
  }
  store.dispatch('users/getRole');
  next({name: 'dashboard'});
};

export const ifAuthenticated = (to, from, next) => {

  if (request.hasToken()) {
    isSignUpWizardCompleted(to, from, next)
    next();
    return;
  }

  localStorage.setItem(
    'redirectRouteObject',
    JSON.stringify({
      fullPath: to.fullPath,
      name: to.name,
      params: to.params
    })
  );
  next({name: 'login'});
};

export const isSignUpWizardCompleted = (to, from, next) => {

  if (!store.state.users.is_setup_completed && to.path !== '/welcome') {
    next('/welcome');

  }
};

const routes = [
  // {
  //   path: '/financial-control',
  //   name: 'financial-control',
  //   component: () => import('./components/dashboard/FinancialControl.vue'),
  //   beforeEnter: (to, from, next) => {
  //     isSignUpWizardCompleted(to, from, next)
  //     let role = localStorage.getItem('user_role');
  //     if (role === 'admin') {
  //       next();
  //     }
  //   },
  // },
  {
    path: '/',
    name: 'home',
    component: () => import('./views/Home.vue'),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./components/Authentication.vue'),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/status',
    name: 'status',
    component: () => import('./views/Status.vue'),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/wholesale/',
    name: 'wholesale',
    component: () => import('./components/Wholesale.vue'),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/consumer',
    name: 'consumer',
    component: () => import('./components/Consumer.vue'),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/:catchAll(.*)",
    component: () => import('./views/E404.vue'),
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('./components/Registration.vue'),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('./views/Success.vue'),
  },
  {
    path: '/information',
    name: 'infoPage',
    component: () => import('./components/TermsConditions.vue'),
  },
  {
    path: '/pending',
    name: 'pending',
    component: () => import('./components/Pending.vue'),
  },
  {
    path: '/reject',
    name: 'reject',
    component: () => import('./views/RejectStatus.vue'),
  },
  {
    path: '/create-password/:param',
    name: 'password',
    component: () => import('./components/CreatePassword.vue'),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/reset-password/:email',
    name: 'reset-password',
    component: () => import('./components/ResetPassword.vue'),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('./views/WelcomeToOrderingPortal/WelcomeToOrderingPortalIndex.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('./components/Dashboard.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/leads',
    name: 'leads',
    component: () => import('./views/Leads/Leads.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/my-jobs',
    name: 'jobs',
    component: () => import('./views/Jobs/Jobs.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/my-jobs/new',
    name: 'create-job',
    component: () => import('./views/Jobs/CreateJob'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/my-jobs/:id',
    name: 'job',
    component: Job,//() => import('./views/Jobs/Job.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/my-orders',
    name: 'my-orders',
    component: () => import('./views/Orders/Orders.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/specification/:type/:id',
    name: 'specification',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/components/item-wizard/components/estimate/specification/index.vue'),
  },
  // {
  //   path: '/productionList',
  //   name: 'productionList',
  //   component: () => import('./components/production/ProductionList.vue'),
  //   beforeEnter: (to, from, next) => {
  //     isSignUpWizardCompleted(to, from, next)
  //     let role = localStorage.getItem('user_role');
  //     if (role === 'admin') {
  //       next();
  //     }
  //   },
  // },
  // {
  //   path: '/finances',
  //   name: 'finances',
  //   component: () => import('./components/dashboard/Finances.vue'),
  //   beforeEnter: (to, from, next) => {
  //     let role = localStorage.getItem('user_role');
  //     if (role === 'admin') {
  //       next();
  //     }
  //   },
  // },
  // {
  //   path: '/dealers',
  //   name: 'dealers',
  //   component: () => import('./components/dashboard/Dealers.vue'),
  //   beforeEnter: (to, from, next) => {
  //     isSignUpWizardCompleted(to, from, next)
  //     let role = localStorage.getItem('user_role');
  //     if (role === 'admin') {
  //       next();
  //     }
  //   },
  // },
  // {
  //   path: '/production',
  //   name: 'production',
  //   component: () => import('./components/production/ProductionList.vue'),
  //   beforeEnter: (to, from, next) => {
  //     isSignUpWizardCompleted(to, from, next)
  //     let role = localStorage.getItem('user_role');
  //     if (role === 'admin') {
  //       next();
  //     }
  //   },
  // },

  OrdersList,
  {
    path: '/customer-details',
    name: 'customer-details',
    component: () => import('./components/orders/CreateOrder.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/select-location',
    name: 'chooseLocation',
    component: () => import('./components/orders/OrderPreview.vue'),
    beforeEnter: ifAuthenticated,
  },
  // {
  //   path: '/order-bulk-edit/:orderId',
  //   name: 'order-bulk-edit',
  //   component: () => import('./components/orders/BulkEdit.vue'),
  //   beforeEnter: ifAuthenticated,
  // },
  {
    path: '/my-jobs/review/:type/:id',
    name: 'view-quote',
    component: () => import('@/components/item-wizard/components/estimate/review/index.vue'),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/my-orders/:id',
    name: 'order-item',
    component: () => import('./views/Orders/item.vue'),
  },
  // {
  //   path: '/order-read/:id',
  //   name: 'order-read',
  //   component: () => import('./components/orders/OrderPreviewRead.vue'),
  //   beforeEnter: ifAuthenticated,
  // },
  {
    path: '/order-preview/:id',
    name: 'order-preview',
    component: () => import('./components/orders/OrderPreview.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/prod-review/:id',
    name: 'prod-review',
    component: () => import('./components/orders/OrderProdReview.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/order-report/:id',
    name: 'order-report',
    component: () => import('./components/orders/OrderReport.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/customers',
    name: 'customers.index',
    component: () => import('./views/Customer/CustomerList.vue'),
  },
  {
    path: '/settings/',
    name: 'settings',
    component: () => import('./views/AccountInformation/AccountInformationIndex.vue'),
    // component: () => import('./components/wholesaleProfile/WholesaleProfile.vue'),
    beforeEnter: ifAuthenticated,
    children: [

      {
        path: 'business-details',
        name: 'account-information.index',
        // component: () => import('./views/AccountInformation/AccountInformationIndex.vue'),
        component: () => import('./views/AccountInformation/BusinessDetails/AccountInformationBusinessDetailsIndex.vue'),
        beforeEnter: ifAuthenticated,
      },
        // children: [
        //   {
        //     path: 'account-information',
        //     name: 'business-details.index',
        //     component: () => import('./views/AccountInformation/BusinessDetails/AccountInformationBusinessDetailsIndex.vue'),
        //   },
          {
            path: 'licenses',
            name: 'business-details.licenses.index',
            component: () => import('./views/AccountInformation/Licences/Index.vue'),
          },
          {
            path: 'default-pricing',
            name: 'business-details.default-price-list',
            component: () => import('./views/AccountInformation/PriceLists/create/CreatePriceList.vue'),
            props: true,

          },
          {
            path: 'price-lists',
            component: () => import('./views/AccountInformation/PriceLists/AccountInformationPriceListsIndex.vue'),
            children: [
              {
                path: '',
                name: 'price-lists.index',
                component: () => import('./views/AccountInformation/PriceLists/ListView.vue'),
              },
              {
                path: 'new',
                name: 'price-lists.create',
                component: () => import('./views/AccountInformation/PriceLists/create/CreatePriceList.vue')
              },
              {
                path: ':pricelistId/edit',
                name: 'price-lists.edit',
                component: () => import('./views/AccountInformation/PriceLists/create/CreatePriceList.vue'),
                props: true,
              }
            ]
          },
          {
            path: 'quoting-portal',
            name: 'quoting-portal-setup.index',
            component: QuotingPortalSetupIndex,
        // ]
      },
      {
        path: 'account',
        name: 'settings.account',
        component: () => import('./components/wholesaleProfile/Account.vue'),
        alias: ''
      },     {
        path: 'financials',
        name: 'financials',
        component: () => import('./components/wholesaleProfile/Financials.vue'),
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('./components/wholesaleProfile/Users.vue'),
        beforeEnter: (to, from, next) => {
          isSignUpWizardCompleted(to, from, next)
          let role = localStorage.getItem('user_role');
          let rights = JSON.parse(localStorage.getItem('abilities') || '[]')

           store.dispatch('auth/getSettingsMenu').then((res) => {
             if((res?.data ?? []).includes('users')){
               next()
             }
           })

          // if (rights.find(ability => _.toLower(ability.name) === _.toLower('manage users')) && ((store.getters['users/multipleAccount'] && role !== 'customer') || role === 'admin' || role === 'manager')) {
          //   next();
          // }
        }
      },
      {
        path: 'customers',
        name: 'customers',
        component: () => import('./views/Customer/CustomerList.vue'),
      },
      {
        path: 'customers/:id',
        name: 'customers.show',
        component: () => import('./views/Customer/CustomerShow.vue'),

      },
      // {
      //   path: 'discounts',
      //   name: 'discounts',
      //   component: () => import('./components/wholesaleProfile/discount/Discounts.vue'),
      //   children: [
      //     {
      //       path: 'products',
      //       name: 'products',
      //       component: () => import('./components/wholesaleProfile/discount/Products.vue'),
      //     },
      //     {
      //       path: 'accessories',
      //       name: 'accessories',
      //       component: () => import('./components/wholesaleProfile/discount/Accessories.vue'),
      //     },
      //     // {
      //     //   path: 'fees',
      //     //   name: 'fees',
      //     //   component: () => import('./components/wholesaleProfile/discount/Fees.vue'),
      //     //   beforeEnter: (to, from, next) => {
      //     //     isSignUpWizardCompleted(to, from, next)
      //     //     let role = localStorage.getItem('user_role');
      //     //     if (role === 'admin') {
      //     //       next();
      //     //     }
      //     //   }
      //     // },
      //   ]
      // },
      {
        path: 'packages',
        name: 'packages',
        component: () => import('./components/wholesaleProfile/Packages.vue'),
        children: [
          {
            path: '/package-builder',
            name: 'package-builder',
            component: () => import('./components/wholesaleProfile/Packages.vue'),
          },
          {
            path: '/package-builder/:type_id(\\d+)',
            name: 'package-builder-type',
            component: () => import('./components/wholesaleProfile/Packages.vue'),
          },
          {
            path: '/package-builder/:type_id(\\d+)/:style_id(\\d+)',
            name: 'package-builder-style',
            component: () => import('./components/wholesaleProfile/Packages.vue'),
          },
          {
            path: '/package-builder/:type_id(\\d+)/:style_id(\\d+)/:grade_id(\\d+)',
            name: 'package-builder-grade',
            component: () => import('./components/wholesaleProfile/Packages.vue'),
          },
          {
            path: '/package-builder/:type_id(\\d+)/:style_id(\\d+)/:grade_id(\\d+)/:option_id(\\d+)',
            name: 'package-builder-option',
            component: () => import('./components/wholesaleProfile/Packages.vue'),
          }
        ]
      },
      {
        path: 'sets',
        name: 'sets',
        component: () => import('./components/wholesaleProfile/Sets.vue'),
        children: [
          {
            path: '/sets-builder',
            name: 'sets-builder',
            component: () => import('./components/wholesaleProfile/Packages.vue'),
          },
          {
            path: '/sets-builder-choice',
            name: 'sets-builder-choice',
            component: () => import('./components/orders/sets/MakeSetButtons.vue'),
          },
          {
            path: '/sets-builder/:type_id(\\d+)',
            name: 'sets-builder-type',
            component: () => import('./components/wholesaleProfile/Packages.vue'),
          },
          {
            path: '/sets-builder/:type_id(\\d+)/:style_id(\\d+)',
            name: 'sets-builder-style',
            component: () => import('./components/wholesaleProfile/Packages.vue'),
          },
          {
            path: '/sets-builder/:type_id(\\d+)/:style_id(\\d+)/:grade_id(\\d+)',
            name: 'sets-builder-grade',
            component: () => import('./components/wholesaleProfile/Packages.vue'),
          },
          {
            path: '/sets-builder/:type_id(\\d+)/:style_id(\\d+)/:grade_id(\\d+)/:option_id(\\d+)',
            name: 'sets-builder-option',
            component: () => import('./components/wholesaleProfile/Packages.vue'),
          }
        ],
      }
    ],

  },

];

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

router.beforeEach(async (to, from) => {

  const routeNamesToUpdateSlash = ['specification']
  if(!routeNamesToUpdateSlash.includes(to.name)) return

  to.fullPath = to.fullPath.replaceAll('%2F','[-]')
  const params = _.cloneDeep(to.params)
  for(let param in params) {
    params[param] = params[param].replaceAll('[-]', '/')
  }

  to.params = params
})

export default router;
/*export default new Router({
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
});*/
